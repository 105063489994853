import { IUsuarioResetPassword } from './../../models/IUsuarioResetPassword';
import { IUsuarioRequest } from './../../models/IUsuarioRequest';
import HttpClient from '../../helpers/axios';
class UsuarioService {
  GetUsuariosByEmail(email: string) {
    return HttpClient.get(`/api/v1/usuarios?usuario=${email}`);
  }

  GetUsuarioById(id: number) {
    return HttpClient.get(`/api/v1/usuarios/${id}`)
  }

  NewUsuario(usuario: IUsuarioRequest) {
    return HttpClient.post('/api/v1/usuarios', usuario)
  }

  UpdateUsuario(usuario: IUsuarioRequest) {
    return HttpClient.put('/api/v1/usuarios', usuario)
  }

  DeleteUsuario(usuarioLogadoContabilistaId: number, id: number) {
    return HttpClient.delete(`/api/v1/usuarios/${usuarioLogadoContabilistaId}/contabilista/${id}`)
  }

  ResetPassword(usuarioReset: IUsuarioResetPassword) {
    return HttpClient.put('/api/v1/usuarios/reset-senha', usuarioReset)
  }
}
export default new UsuarioService()