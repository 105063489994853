import { useToast } from 'vue-toastification';
import { IUsuarioResetPassword } from './../../../models/IUsuarioResetPassword';
import { IUsuarioRequest } from './../../../models/IUsuarioRequest';
import { authStore } from './../auth/index';
import UsuarioService from '../../../services/usuarios/UsuarioService';
import { defineStore } from 'pinia';
import eventBus from '../../../helpers/event-bus';

const toast = useToast()
const emitPopup = eventBus.usePopup();
const emitDataSource = eventBus.useDataSource();

export const usuarioStore = defineStore({
  id: 'usuarioStore',
  state: () => ({
    auth: authStore(),
    usuario: {} as IUsuarioRequest
  }),
  actions: {
    SUBMIT_FORM(payload: IUsuarioRequest) {
      payload.id === 0 ? this.NEW_USUARIO(payload) : this.UPDATE_USUARIO(payload)
    },

    async LOAD() {
      try {
        const response = await UsuarioService.GetUsuariosByEmail(this.auth.currentUser.email)
        if (response.status === 200) return response.data
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async NEW_USUARIO(payload: IUsuarioRequest) {
      try {
        const response = await UsuarioService.NewUsuario(payload);
        if (response.status === 200) {
          emitPopup.close();
          toast.success('Usuário cadastrado com sucesso!');
          emitDataSource.reload();
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async UPDATE_USUARIO(payload: IUsuarioRequest) {
      try {
        const response = await UsuarioService.UpdateUsuario(payload)
        if (response.status === 200) {
          emitPopup.close();
          toast.success('Usuários atualizado com sucesso!');
          emitDataSource.reload()
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async DELETE_USUARIO(payloadUserLoggedContabilistaId: number, payload: number) {
      try {
        const response = await UsuarioService.DeleteUsuario(payloadUserLoggedContabilistaId, payload);
        if (response.status === 200) {
          toast.success('Usuário apagado com sucesso!');
          emitDataSource.reload();
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async GET_USER_BY_ID(payload: number) {
      try {
        const response = await UsuarioService.GetUsuarioById(payload);
        if (response.status === 200) {
          this.usuario = response.data
          toast.info('Usuário encontrado com sucesso!');
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    },

    async RESET_PASSWORD(payload: IUsuarioResetPassword) {
      try {
        const response = await UsuarioService.ResetPassword(payload)
        if (response.status === 200) {
          emitPopup.close()
          toast.success('Senha Atualizada com sucesso!');
        }
      } catch (error: any) {
        toast.error(error.response.data)
      }
    }
  }
})