import eventBus from '../../../helpers/event-bus';
import { IUsuarioResetPassword } from '../../../models/IUsuarioResetPassword';
import { DxForm, DxSimpleItem, DxCompareRule, DxRequiredRule, DxButtonItem, DxButtonOptions } from 'devextreme-vue/ui/form';
import { defineComponent, reactive, ref } from "vue";
import { usuarioStore } from '../../../store/modules/usuarios';

export default defineComponent({
  name: 'FormResetSenha',
  props: {
    propsComponent: {
      required: true,
      type: Object as () => IUsuarioResetPassword
    }
  },
  setup(props: any) {
    const form = ref()
    const popup = eventBus.usePopup()
    const store = usuarioStore()
    const state = reactive({
      usuario: props.propsComponent
    })

    const saveButtonProperties = {
      text: 'Salvar',
      type: 'default',
      stylingMode: 'text',
      icon: 'mdi mdi-content-save',
      useSubmitBehavior: true,
    }

    const cancelButtonProperties = {
      text: 'Cancelar',
      type: 'danger',
      stylingMode: 'text',
      onClick: () => popup.close()
    }

    function ConfirmPassword() {
      return state.usuario.password
    }

    return {
      form,
      state,
      store,
      saveButtonProperties,
      cancelButtonProperties,
       ConfirmPassword
    }

  },
  components: {
    DxForm,
    DxSimpleItem,
    DxCompareRule,
    DxRequiredRule,
    DxButtonItem,
    DxButtonOptions
  }
})